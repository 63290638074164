import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { Router  } from 'react-router-dom';
import Routes from './components/Configuration/Routes';
import createHistory from "history/createBrowserHistory";

import MainLayout from './components/Shared/Layouts/MainLayout';

const history = createHistory();

ReactDOM.render(
  <React.StrictMode>
      <MainLayout>
        <Router history={history}>
          <Routes />
        </Router>
      </MainLayout>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
