import AppSettings from '../../data/Settings.json';
import Settings from '../Models/Configuration/Settings';
import IDataService from "./Interfaces/IDataService";

class SettingsService implements IDataService {
    baseUrl: string = "";

    GetSettings(): Settings {
        const appSettings = AppSettings;
        return new Settings(appSettings.Theme, appSettings.BrandName, appSettings.Twitter, appSettings.CopyRight);
    }

    GetEndpoint(method: string): string {
        let endpoints: { [key: string]: string } = {
            'GET': '/Settings.json',
        };

        return endpoints[method];
    }
}

export default SettingsService;