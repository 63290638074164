import React from 'react';
import Settings from '../../Models/Configuration/Settings';
import SettingsService from '../../Services/SettingsService';
import AppFooter from '../Parts/AppFooter';
import AppHeader from '../Parts/AppHeader';

type MainLayoutState = {
    Settings: Settings
}

class MainLayout extends React.Component<{}, MainLayoutState>  {
    componentWillMount() {
        const settingsService = new SettingsService();

        this.setState({
            Settings: settingsService.GetSettings()
        });
    }

    render() {
        return <div className="text-gray-400 bg-gray-900 flex flex-col h-screen">
            {this.state.Settings && <AppHeader Settings={this.state.Settings} />}
            
            <main className="flex-grow"> 
                {this.props.children}
            </main>

            {this.state.Settings && <AppFooter Settings={this.state.Settings} />}
        </div>
    }
}

export default MainLayout;