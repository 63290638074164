class Navigation {
    constructor(title: string, url: string) {
        this.url = url;
        this.title = title;
    }

    url: string;
    title: string;
}

export default Navigation;