import React from 'react';
import Steps from './Parts/Steps';

class HowItWorksPage extends React.Component {
    render() {
        return <div>
            <Steps />
        </div>
    }
}

export default HowItWorksPage;