import React from 'react'
import Team from '../../Shared/Team'

class TeamPage extends React.Component{
    render(){ 
        return <div>
            <Team/>
        </div>
    }
}

export default TeamPage;