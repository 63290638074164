import React from 'react'
import Pricing from '../../Shared/Pricing'

class PricingPage extends React.Component {
    render() {
        return <div>
            <Pricing />
        </div>
    }
}

export default PricingPage;