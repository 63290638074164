class Settings {
    Theme: string;
    BrandName: string;
    Twitter: string;
    CopyRight: string;

    constructor(theme: string, brandName: string, twitter: string, copyRight: string) {        
        this.Theme = theme;
        this.BrandName = brandName;
        this.Twitter = twitter;
        this.CopyRight = copyRight;
    }
}

export default Settings;