import React from 'react'
import Contact from '../../Shared/Contact';

class ContactPage extends React.Component{
    render(){
        return <div>
            <Contact/>
        </div>
    }
}

export default ContactPage;