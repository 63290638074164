import React from 'react'
import NotFoundImage from '../../../../img/notfound.jpg'

class NotFoundPage extends React.Component {


    render() {
        return <section className="text-gray-400 bg-gray-900 body-font">
            <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
                <img 
                    className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" 
                    alt="Dead end roadsign by https://unsplash.com/@randylaybourne" 
                    src={NotFoundImage} 
                />
                <div className="text-center lg:w-2/3 w-full">
                    <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Error 404: Not Found</h1>
                    <p className="leading-relaxed mb-8">This is not the page you are looking for. Please check the url that you are trying to access or go to the home page.</p>
                    <div className="flex justify-center">
                        <a
                            href="\"
                            rel="noopener noreferer"
                            className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg">
                            Home
                        </a>
                    </div>
                </div>                
            </div>
        </section>;
    }
}

export default NotFoundPage;