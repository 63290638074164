import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ContactPage from '../Pages/ContactPage/ContactPage';
import NotFoundPage from '../Pages/Errors/NotFoundPage/NotFoundPage';
import HomePage from '../Pages/HomePage/HomePage';
import HowItWorksPage from '../Pages/HowItWorksPage/HowItWorksPage';
import PricingPage from '../Pages/PricingPage/PricingPage';
import TeamPage from '../Pages/TeamPage/TeamPage';

const Routes = function () {
    return (
        <Router>
            <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/HowItWorks" component={HowItWorksPage} />
            <Route exact path="/Pricing" component={PricingPage} />
            <Route exact path="/Team" component={TeamPage} />
            <Route exact path="/Contact" component={ContactPage} />
            <Route component={NotFoundPage} />
            </Switch>
        </Router>
    );
}

export default Routes 