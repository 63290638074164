import React from 'react';
import Settings from '../../Models/Configuration/Settings';
import Navigation from '../../Models/Navigation';

type IAppHeaderProps = {
    Settings: Settings
}

class AppHeader extends React.Component<IAppHeaderProps> {
    getNavigation() {
        let list: Navigation[] = [];
        list.push(new Navigation('How it works', 'HowItWorks'));
        list.push(new Navigation('Pricing', 'Pricing'));
        list.push(new Navigation('Team', 'Team'));
        list.push(new Navigation('Contact', 'Contact'));
        return list;
    }

    render() {
        const navigation = this.getNavigation();
        const { BrandName } = this.props.Settings;

        return <header className="text-gray-400 bg-gray-900 body-font">
            <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <a href="\" rel="noopener noreferrer" className="flex title-font font-medium items-center text-white mb-4 md:mb-0">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-10 h-10 text-white p-2 bg-yellow-500 rounded-full" viewBox="0 0 24 24">
                        <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
                    </svg>
                    <span className="ml-3 text-xl">{BrandName}</span>
                </a>
                <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
                    {navigation.map((item, i) => {
                        return (
                            <a key={i} href={item.url} rel="noopener noreferrer" className="mr-5 hover:text-white">{item.title}</a>
                        );
                    })}
                </nav>
            </div>
        </header>;
    }
}

export default AppHeader;