import React from 'react';
import Pricing from '../../Shared/Pricing';
import Team from '../../Shared/Team';
import Features from './Parts/Features';
import Hero from './Parts/Hero';
import Statistics from './Parts/Statistics';
import Testimonials from './Parts/Testimonials';

class HomePage extends React.Component {
    render() {
        return <div>
            <Hero />
            <Features />
            <Statistics />
            <Pricing />
            <Team />
            <Testimonials/>
        </div>
    }
}

export default HomePage;